<template>
<div class="map-wrapper">
    <div :class="['panel', { active: isShowMore }]">
        <div class="col">
            <div class="row">
                <span class="label">交易状态</span>
                <div class="options">
                    <div v-for="(item, index) in dealStateList" :key="item.id" :class="['option', 'checkList', `deal-state-${item.id}`]" @click="chooseDealStatus(item, index)">
                        <span :class="['checkbox', { checked: item.checked }]"></span>
                        <span class="text">{{ item.name }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <span class="label">起始时间</span>
                <div class="options">
                    <div class="option start-time">
                        <Date-picker type="date" clearable placeholder="开始时间" style="width: 96px" format="yyyy-MM-dd" :value="startTime[0]" @on-change="changeStartTime"></Date-picker>
                    </div>
                    <div class="hr"></div>
                    <div class="option end-time">
                        <Date-picker type="date" clearable placeholder="结束时间" format="yyyy-MM-dd" style="width: 96px; font-size: 12px" :value="startTime[1]" @on-change="changeEndTime"></Date-picker>
                    </div>
                </div>
            </div>
            <div class="row">
                <span class="label">起始价</span>
                <div class="options">
                    <div class="option start-time">
                        <Input placeholder="起始价" type="number" style="width: 96px" v-model="startPrice[0]" />
                    </div>
                    <div class="hr"></div>
                    <div class="option end-time">
                        <Input placeholder="起始价" type="number" style="width: 96px; font-size: 12px" v-model="startPrice[1]" />
                    </div>
                    <div class="unit">万元</div>
                </div>
            </div>
            <div class="row">
                <span class="label">推出单价</span>
                <div class="options">
                    <div class="option start-time">
                        <Input placeholder="推出单价" type="number" style="width: 96px" v-model="unitPrice[0]" />
                    </div>
                    <div class="hr"></div>
                    <div class="option end-time">
                        <Input placeholder="推出单价" type="number" style="width: 96px; font-size: 12px" v-model="unitPrice[1]" />
                    </div>
                    <div class="unit">元/m²</div>
                </div>
            </div>
            <div class="row">
                <span class="label">成交楼面价</span>
                <div class="options">
                    <div class="option start-time">
                        <Input placeholder="成交楼面价" type="number" style="width: 96px" v-model="floorPrice[0]" />
                    </div>
                    <div class="hr"></div>
                    <div class="option end-time">
                        <Input placeholder="成交楼面价" type="number" style="width: 96px; font-size: 12px" v-model="floorPrice[1]" />
                    </div>
                    <div class="unit">元/m²</div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="row landuse">
                <span class="label">用地性质</span>
                <div class="options">
                    <div v-for="(item, index) in landUseList" :key="item.id" class="option checkList" @click="chooseLandNature(item, index)">
                        <span :class="[
                  'checkbox',
                  { checked: landUse.includes(item.id.toString()) },
                ]"></span>
                        <span class="text">{{ item.name }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <span class="label">地块名称</span>
                <div class="options">
                    <div class="option">
                        <Input v-model="landName" style="width: 216px" placeholder="地块名称" />
                    </div>
                </div>
            </div>
            <div class="row">
                <span class="label">受让单位</span>
                <div class="options">
                    <div class="option">
                        <Input v-model="partier" style="width: 216px" placeholder="受让单位" />
                    </div>
                </div>
            </div>
            <div class="row">
                <span class="label">成交时间</span>
                <div class="options">
                    <div class="option start-time">
                        <Date-picker clearable type="date" placeholder="开始时间" style="width: 96px" format="yyyy-MM-dd" :value="closingTime[0]" @on-change="changeCloseTimeLeft"></Date-picker>
                    </div>
                    <div class="hr"></div>
                    <div class="option end-time">
                        <Date-picker clearable type="date" placeholder="结束时间" format="yyyy-MM-dd" style="width: 96px; font-size: 12px" :value="closingTime[1]" @on-change="changeCloseTimeRight"></Date-picker>
                    </div>
                </div>
            </div>
            <div class="row">
                <span class="label">成交价</span>
                <div class="options">
                    <div class="option start-time">
                        <Input placeholder="成交价" type="number" style="width: 96px" v-model="transactionTotalPrice[0]" />
                    </div>
                    <div class="hr"></div>
                    <div class="option end-time">
                        <Input placeholder="成交价" type="number" style="width: 96px; font-size: 12px" v-model="transactionTotalPrice[1]" />
                    </div>
                    <div class="unit">万元</div>
                </div>
            </div>
        </div>
        <div class="col last">
            <div class="row" style="margin-top: 62px">
                <span class="label">推出楼面价</span>
                <div class="options">
                    <div class="option start-time">
                        <Input v-model="launchFloorPrice[0]" type="number" placeholder="推出楼面价" style="width: 96px" />
                    </div>
                    <div class="hr"></div>
                    <div class="option end-time">
                        <Input v-model="launchFloorPrice[1]" placeholder="推出楼面价" type="number" style="width: 96px; font-size: 12px" />
                    </div>
                    <div class="unit">元/m²</div>
                </div>
            </div>
            <div class="row">
                <span class="label">成交单价</span>
                <div class="options">
                    <div class="option start-time">
                        <Input placeholder="成交单价" type="number" style="width: 96px" v-model="transactionPrice[0]" />
                    </div>
                    <div class="hr"></div>
                    <div class="option end-time">
                        <Input placeholder="成交单价" type="number" style="width: 96px; font-size: 12px" v-model="transactionPrice[1]" />
                    </div>
                    <div class="unit">元/m²</div>
                </div>
            </div>
            <div class="row">
                <span class="label">用地面积</span>
                <div class="options">
                    <div class="option start-time">
                        <Input placeholder="用地面积" type="number" style="width: 96px" v-model="landArea[0]" />
                    </div>
                    <div class="hr"></div>
                    <div class="option end-time">
                        <Input placeholder="用地面积" type="number" style="width: 96px; font-size: 12px" v-model="landArea[1]" />
                    </div>
                    <div class="unit">m²</div>
                </div>
            </div>
            <div class="row">
                <span class="label">建筑面积</span>
                <div class="options">
                    <div class="option start-time">
                        <Input placeholder="建筑面积" type="number" style="width: 96px" v-model="buildArea[0]" />
                    </div>
                    <div class="hr"></div>
                    <div class="option end-time">
                        <Input placeholder="建筑面积" type="number" style="width: 96px; font-size: 12px" v-model="buildArea[1]" />
                    </div>
                    <div class="unit">m²</div>
                </div>
            </div>
        </div>
        <div class="col more">
            <div class="row">
                <div class="label-box" @click="showMorePanel">
                    <span class="label">更多条件</span>
                    <img :src="moreIcon" />
                </div>
                <div class="options">
                    <div class="option">
                        <Button type="primary" class="searchBtnLog" data-id="1" @click="handleSearchClick">搜索</Button>
                    </div>
                    <div class="option empty" @click="handleEmpty">
                        <Button>清空</Button>
                    </div>
                </div>
            </div>
        </div>
        <div class="pack-up" @click="closeMorePanel">
            <Icon type="ios-arrow-up"></Icon>
        </div>
    </div>

    <div :class="['tool-bar', { active: isShowMore }]">
        <div class="position">
            <span class="label">快速定位：</span>
            <div class="options">
                <div class="option">
                    <Dropdown trigger="click" @on-click="selectProvince">
                        <a href="javascript:void(0)">
                            {{ province }}
                            <Icon type="ios-arrow-down"></Icon>
                        </a>
                        <DropdownMenu slot="list">
                            <DropdownItem v-for="item in landProvinceList" :name="item.areaId" :key="item.areaId">{{ item.name }}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div class="option">
                    <Dropdown trigger="click" @on-click="selectCity">
                        <a href="javascript:void(0)">
                            {{ city }}
                            <Icon type="ios-arrow-down"></Icon>
                        </a>
                        <DropdownMenu slot="list">
                            <DropdownItem v-for="item in cityList" :name="item.areaId" :key="item.areaId">{{ item.name }}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div class="option district">
                    <Dropdown trigger="custom" @on-click="selectRegion" :visible="false">
                        <a href="javascript:void(0)">
                            <!-- {{ region || '请选择' }} -->
                            行政区
                            <Icon type="ios-arrow-down"></Icon>
                        </a>
                        <DropdownMenu slot="list">
                            <DropdownItem v-for="item in regionList" :name="item.areaId" :key="item.areaId" disabled>{{ item.name }}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
        </div>
        <div class="tools">
            <span class="label">工具：</span>
            <div class="options">
                <!-- <div class="option" @click="dropSearch">控框搜索</div> -->
                <div class="option radius">
                    <p :class="['btn', { active: isShowRadiusSearch }]" @click="showRadiusSearch">
                        <span class="text">半径搜索</span>
                        <Icon type="ios-arrow-down" />
                    </p>

                    <div class="border" v-show="isShowRadiusSearch" @click="closeRadiusSearch"></div>

                    <div class="search-box" v-show="isShowRadiusSearch">
                        <div class="input-box km">
                            <Input v-model="radius" size="small" style="width: 43px" maxlength="4" />
                            <span>公里</span>
                        </div>
                        <Button size="small" type="primary" @click="radiusSearch">确定</Button>
                    </div>
                </div>
                <div class="option" @click="ranging">测距</div>
                <div class="option" @click="measureArea">测面积</div>
                <div class="option clear" @click="clearOverlay">清除覆盖物</div>
            </div>
        </div>
    </div>

    <div class="map-contaner">
        <div class="map" id="map"></div>

        <div class="result-list">
            <div class="tabs">
                <div :class="['tab', { active: paneIndex === 1 }]" @click="changeListPane(1)">
                    搜索结果
                </div>
                <div :class="['tab', { active: paneIndex === 2 }]" @click="changeListPane(2)">
                    周边搜索
                </div>
            </div>
            <div class="pane">
                <div class="list list1" v-show="paneIndex === 1" @scroll="scrollLoad">
                    <div class="banner">
                        <span class="left">共{{ total }}</span>
                        <div class="sort">
                            <div class="item" v-for="(item, index) in sortList" :key="index" @click="handleResultSort(item)">
                                {{ item.name }}
                                <i :class="['sort-icon', { active: index === sortType }]"></i>
                            </div>
                        </div>
                    </div>
                    <div class="search-result result" v-for="(item, index) in mapDataList" :key="index" @click="clickResult(item)">
                        <div class="row">
                            <span class="deal-state tab">{{ item.stateText }}</span>
                            <span class="title" :title="item.zdbhCr">{{
                  item.zdbhCr
                }}</span>
                            <span class="price" :title="item.cjlmj"><span class="num">{{ item.cjlmj }}</span>元/m²</span>
                        </div>
                        <div class="row">
                            <span class="land-use tab" :title="item.tdyt">{{
                  item.tdyt
                }}</span>
                            <a class="link zb" @click.stop="showNearby(item)">周边</a>
                            <a class="link pg" @click.stop="handleAddProject(item)">看项目</a>
                            <span class="date">{{
                  item.gpjzsj !== "null" ? item.gpjzsj : ""
                }}</span>
                            <span class="price lmj" :title="item.cjjms">{{
                    item.cjjms != "" ? (item.cjjms / 10000).toFixed(2) : ""
                  }}亿元</span>
                        </div>
                    </div>
                </div>
                <div class="list" v-show="paneIndex === 2">
                    <div class="nearby-banner">
                        <div class="first">
                            <div :class="['item', item.type]" v-for="item in nearbyList.first" :key="item.id" :style="item.style" @click="searchNearBy(item.name)">
                                {{ item.name }}
                            </div>
                        </div>
                        <div class="second">
                            <div :class="['item', item.type]" v-for="item in nearbyList.second" :key="item.id" @click="searchNearBy(item.name)">
                                {{ item.name }}
                            </div>
                            <div class="nearby-result result" id="p-result"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <addProjectModal :address="address" :id="addProjectId" type="place"></addProjectModal>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
import BMap from "BMap";
import BMapLib from "BMapLib";
import addProjectModal from "@/components/modal/addProject";
import {
    getMapData
} from "@/api/mapQuery.js";
import {
    getLandSingleSelectProvince,
    getLandSingleSelectCity,
    getLandSingleSelectRegion,
    getDealState,
} from "@/api/public";
import dataReports from "@/mixins/dataReports";

export default {
    name: "Map",
    components: {
        addProjectModal,
    },
    mixins: [dataReports],
    data() {
        return {
            landName: "",
            // 受让单位
            partier: "",
            map: null,
            pullBox: null, // 拉框搜索实例
            mapDis: null, // 测距实例
            drawingManager: null, // 测面积实例
            mapData: {
                zoom: 12,
                center: {
                    lng: 116.46,
                    lat: 39.92,
                },
            },
            isShowMore: false,
            // 交易状态
            dealState: [],
            // 用地性质
            landUse: [],
            city: "",
            cityId: "",
            province: "",
            provinceId: "",
            region: "",
            regionId: "",
            currentIndex: 1,
            size: 10,

            rangeOption: {
                startingTime: "起始时间",
                startPrice: "起始价",
                unitPrice: "推出单价",
                launchFloorPrice: "推出楼面价",
                closingTime: "成交时间",
                landArea: "用地面积",
                transactionTotalPrice: "成交价",
                floorPrice: "成交楼面价",
                transactionPrice: "成交单价",
                buildArea: "建筑面积",
            },
            // 起始时间
            startTime: [],
            // 起始价
            startPrice: [],
            // 推出单价
            unitPrice: [],
            // 推出楼面价
            launchFloorPrice: [],
            // 成交时间
            closingTime: [],
            // 用地面积
            landArea: [],
            // 成交价
            transactionTotalPrice: [],
            // 成交楼面价
            floorPrice: [],
            // 成交单价
            transactionPrice: [],
            // 建筑面积
            buildArea: [],

            // 地图是否已经加载好
            isMapReady: false,
            // 是否开启拉框搜索
            isPullBoxOpen: false,
            // 是否开始测距
            isRangingOpen: false,
            // 是否开始测面积
            isMeasureArea: false,

            // 地图标注icon图片
            liveIcon: require("@/assets/image/map/live_icon.png"),
            liveIconActive: require("@/assets/image/map/live_active.png"),
            gongIcon: require("@/assets/image/map/gong.png"),
            gongIconActive: require("@/assets/image/map/gong_active.png"),
            shangIcon: require("@/assets/image/map/shang.png"),
            shangIconActive: require("@/assets/image/map/shang_active.png"),
            qitaIcon: require("@/assets/image/map/qita.png"),
            qitaIconActive: require("@/assets/image/map/qita_active.png"),
            closeIcon: require("@/assets/image/map/close.png"),
            overlayCloseIcon: require("@/assets/image/map/overLay_close.svg"),

            // 更多icon
            moreIcon: require("@/assets/image/map/more.png"),

            sortList: [{
                    id: 0,
                    name: "截止日期",
                    checked: true,
                    type: "asc",
                },
                {
                    id: 1,
                    name: "成交价",
                    checked: false,
                    type: "asc",
                },
                {
                    id: 2,
                    name: "楼面价",
                    checked: false,
                    type: "asc",
                },
            ],
            sortType: 0,
            isShowRadiusSearch: false,

            // 半径搜索参数
            radiusKeyWord: "",
            radius: "",
            radiusSearchPoint: null,

            mapDataList: [],
            originMapDataList: [],
            pointList: [],
            total: 0,
            selectData: null,
            point: null,

            landProvinceList: [],
            cityList: [],
            regionList: [],

            localSearch: null,
            nearbySearch: null,

            paneIndex: 1,
            nearbyList: {
                first: [{
                        id: 0,
                        name: "找美食",
                        type: "food",
                        style: "background-position: 0px -190px;",
                    },
                    {
                        id: 1,
                        name: "订酒店",
                        type: "hotel",
                        style: "background-position: 0px -214px;",
                    },
                    {
                        id: 2,
                        name: "公交站",
                        type: "bus",
                        style: "background-position: 0px -238px;",
                    },
                ],
                second: [{
                        id: 0,
                        name: "景点",
                        type: "site",
                    },
                    {
                        id: 1,
                        name: "电影院",
                        type: "cinema",
                    },
                    {
                        id: 2,
                        name: "银行",
                        type: "bank",
                    },
                    {
                        id: 3,
                        name: "专享车险",
                        type: "carInsurance",
                    },
                ],
            },

            polygonIndex: 0,
            polygonList: [],
            polygonLabelList: [],

            dealStateList: [],
            // clearable: false
            addProjectId: -1,
            address: {},
            //排序函数
            orderBy: 2,
        };
    },
    computed: {
        ...mapState({
            landUseList: (state) =>
                state.landUse.filter((item) => !item.name.includes("+")),
        }),
        showAddProjectModal: {
            get() {
                return this.$store.state.modal.showAddProjectModal;
            },
            set(value) {
                this.$store.state.modal.showAddProjectModal = value;
            },
        },
        showAdviceModal: {
            get() {
                return this.$store.state.modal.showAdviceModal;
            },
            set(newValue) {
                this.$store.state.modal.showAdviceModal = newValue;
            },
        },
    },
    watch: {
        orderBy(newValue, oldValue) {
            this.currentIndex = 1
            this.clearOverlay()
            this.search()
        }
    },
    beforeMount() {
        this.$Notice.destroy();
    },
    mounted() {
        this.getDealState();
        this.loadMap(BMap);
        this.$Notice.info({
            title: "温馨提示",
            render: (h) => {
                return h("div", [
                    `${this.noticeInfo.dataDesc[2023][0]}`,
                    h(
                        "a", {
                            style: {
                                color: "blue",
                            },
                            on: {
                                click: () => {
                                    this.showAdviceModal = true;
                                    this.$Notice.close("mapDesc");
                                },
                            },
                        },
                        `${this.noticeInfo.dataDesc[2023][1]}`
                    ),
                ]);
            },
            name: "mapDesc",
            duration: 10,
        });
        // this.$msg.success("dataDesc", 2023);
    },
    methods: {
        getDealState() {
            getDealState().then((data) => {
                this.dealStateList = data.map((item, index) => {
                    index === 0 ? (item.checked = true) : (item.checked = false);
                    return item;
                });
                this.dealState.push(this.dealStateList[0].id);
            });
        },
        loadMap(BMap) {
            this.map = new BMap.Map("map");
            this.map.centerAndZoom(
                new BMap.Point(this.mapData.center.lng, this.mapData.center.lat),
                this.mapData.zoom
            );
            this.map.enableScrollWheelZoom();

            this.isMapReady = true;
            this.getPosition(BMap);
            this.initDrawingManager();
            this.initRanging(); // 初始化测距
            this.initNearBySearch(); // 初始化周边搜索
        },
        // 根据ip定位
        getPosition(BMap) {
            this.province = "广东省";
            this.city = "广州市";
            this.region = "南沙区";

            getLandSingleSelectProvince().then((data) => {
                this.landProvinceList = data;
                for (let i = 0; i < this.landProvinceList.length; i++) {
                    if (this.landProvinceList[i].name + "省" === this.province) {
                        this.provinceId = this.landProvinceList[i].areaId;
                        break;
                    }
                }

                getLandSingleSelectCity({
                    id: this.provinceId,
                }).then((data) => {
                    this.cityList = data;
                    for (let i = 0; i < this.cityList.length; i++) {
                        if (this.cityList[i].name + "市" === this.city) {
                            this.cityId = this.cityList[i].areaId;
                            break;
                        }
                    }
                    if (this.cityId == -1) {
                        this.cityId = this.cityList[0].areaId;
                    }
                    this.handleSearchClick();
                    // getLandSingleSelectRegion({
                    //     id: this.cityId,
                    // }).then((data) => {
                    //     this.regionList = data;
                    //     for (let i = 0; i < this.regionList.length; i++) {
                    //         if (this.regionList[i].name === this.region) {
                    //             this.regionId = this.regionList[i].areaId;
                    //             break;
                    //         }
                    //     }
                    //     this.handleSearchClick();
                    // });

                    if (this.cityId == -1) {
                        this.regionId = this.regionList[0].areaId;
                    }
                });
            });
        },
        //根据经纬度解析地址
        getlocateByCor(BMap, {
            lng,
            lat
        }) {
            return new Promise((res, rej) => {
                // 创建地理编码实例
                const myGeo = new BMap.Geocoder();
                // 根据坐标得到地址描述
                myGeo.getLocation(new BMap.Point(lng, lat), function (result) {
                    if (result) {
                        res(result.addressComponents);
                    } else {
                        rej();
                        throw "经纬度解析错误";
                    }
                });
            });
        },
        showMorePanel() {
            this.isShowMore = !this.isShowMore;
        },
        closeMorePanel() {
            this.isShowMore = false;
        },
        chooseDealStatus(item, statusIndex) {
            if (item.checked) {
                item.checked = false;
                this.dealState.splice(this.dealState.indexOf(item.id), 1);
            } else {
                item.checked = true;
                this.dealState.push(item.id);
            }

            this.$set(this.dealStateList, statusIndex, item);
        },
        chooseLandNature(item, useIndex) {
            if (item.checked) {
                item.checked = false;
                this.landUse.splice(this.landUse.indexOf(item.id.toString()), 1);
            } else {
                item.checked = true;
                this.landUse.push(item.id.toString());
            }
        },
        handleSearchClick(e) {
            this.search(e);
        },
        // 搜索
        search(e) {
            if (!this.isMapReady) {
                // this.$Message.warning('地图尚未加载完成');
                this.$msg.error({
                    text: "地图尚未加载完成",
                });

                return;
            }
            if(e&&e.type=='click') {
                this.mapDataList = []
            }
            const param = {
                province: this.provinceId,
                city: this.cityId,
                region: this.regionId,
                transactionStatus: this.dealState,
                landUse: this.landUse,
                landName: this.landName,
                effectivePrescription: this.partier,
                startingTime: this.startTime,
                startPrice: this.startPrice,
                launchFloorPrice: this.launchFloorPrice,
                unitPrice: this.unitPrice,
                closingTime: this.closingTime,
                landArea: this.landArea,
                floorPrice: this.floorPrice,
                transactionPrice: this.transactionPrice,
                buildArea: this.buildArea,
                current: this.currentIndex,
                transactionTotalPrice: this.transactionTotalPrice,
                orderBy: this.orderBy,
                size: 30,
            };

            for (let key in this.rangeOption) {
                let start = param[key][0];
                let end = param[key][1];
                // 都是空值 继续
                if (!start && !end) continue;
                // 只有单一值 返回
                if (!start || !end) {
                    // this.$Message.warning(`${this.rangeOption[key]}，请选择一个范围`);
                    this.$msg.error({
                        text: `${this.rangeOption[key]}，请选择一个范围`,
                    });
                    return;
                }
            }

            getMapData(param).then((data) => {
                if (data.records.length === 0) {
                    // this.$Message.warning('所选区域暂无数据');
                    this.$msg.error({
                        text: "所选区域暂无数据或数据建设中，请等等",
                    });

                    return;
                }
                this.mapDataList = this.mapDataList.concat(
                    data.records
                    .map((item) => {
                        item.cjlmj = Math.round(item.cjlmj);
                        return item;
                    })
                    .filter((item) => item.jd && item.wd)
                );

                if (this.mapDataList.length === 0) {
                    // this.$Message.warning('所选区域暂无数据');
                    this.$msg.error({
                        text: "所选区域暂无数据或数据建设中，请等等",
                    });

                    return;
                }

                this.total = data.total;
                this.map.setCenter(
                    new BMap.Point(this.mapDataList[0].jd, this.mapDataList[0].wd)
                );
                this.genMapIcon();

                this.mapDataList.forEach((item) => {
                    this.pointList.push(new BMap.Point(item.jd, item.wd));
                });

                this.originMapDataList = this.mapDataList;
            });
        },
        handleEmpty() {
            this.dealStateList.forEach((item) => {
                item.checked = false;
            });
            this.landUseList.forEach((item) => {
                item.checked = false;
            });
            this.startTime = [];
            this.startPrice = [];
            this.unitPrice = [];
            this.launchFloorPrice = [];
            this.closingTime = [];
            this.landArea = [];
            this.transactionTotalPrice = [];
            this.floorPrice = [];
            this.transactionPrice = [];
            this.buildArea = [];
        },
        genInfoBox() {
            this.map.getOverlays().forEach((ol) => {
                if (ol instanceof BMapLib.InfoBox) {
                    this.map.removeOverlay(ol);
                }
            });
            const item = this.selectData;

            const content = `
                <div class="info-box-inner">
                    <div class="title">
                        <div class="text">
                            <span title="${item.zdbhCr}">${item.zdbhCr}</span>
                            [${item.stateText}]
                        </div>
                        <div class="close">
                            <img src="${this.closeIcon}"/>
                        </div>
                    </div>
                    <div class="info">
                        <div class="row">

                            <div class="col" :title="item.tdytCr">土地用途：${item.tdytCr}</div>
                            <div class="col price"><span>${item.cjlmj}</span>元/m²</div>
                        </div>
                        <div class="row">
                            <div class="col"  title="${item.dkwz}">地址：${item.dkwz}</div>
                        </div>
                    </div>
                    <div class="tool">
                        <a class="item detail">查看详情</a>
                        <a class="item lookProject" >看项目</a>
                        <a class="item hidden" style="display: none">隐藏地块</a>
                    </div>
                    <div class="search">
                        <div id="tabs" class="tabs">
                            <div class="tab active">周边查询</div>
                            <div class="tab">距离查询</div>
                        </div>
                        <div class="query">
                            <select class="dis">
                                <option>1km</option>
                                <option>2km</option>
                            </select>
                            <i class="icon-down"></i>
                            <input class="keyword"/>
                            <button class="search-btn">查找</button>
                        </div>
                    </div>
                </div>
            `;

            const infoBox = new BMapLib.InfoBox(this.map, content, {
                boxStyle: {
                    background: "#fff",
                    width: "272px",
                    height: "195px",
                    padding: "16px 14px 14px 12px",
                    "font-size": "12px",
                    cursor: "default",
                },
                closeIconMargin: "4px 14px 0 0",
                enableAutoPan: true,
                offset: new BMap.Size(0, 20),
            });

            infoBox.addEventListener("open", () => {
                const $tabs = $("#tabs");
                const $closeBtn = $(".info-box-inner .close");
                const $toDetail = $(".info-box-inner .tool .detail");
                const $hidden = $(".info-box-inner .tool .hidden");
                const $lookPro = $(".info-box-inner .lookProject");

                $tabs.on("click", "div", (event) => {
                    const target = $(event.target);
                    if (!target.hasClass("active")) {
                        target.addClass("active");
                        target.siblings().removeClass("active");
                    }
                });

                $lookPro.on("click", () => {
                    this.handleAddProject(item);
                });

                $toDetail.on("click", () => {
                    const {
                        href
                    } = this.$router.resolve({
                        path: `/Insight/PlaceDetail`,
                        query: {
                            id: item.id,
                            type: "option",
                        },
                    });
                    window.open(href);
                });
                $hidden.on("click", () => {
                    const overlays = this.map.getOverlays();

                    for (let i = 0; i < overlays.length; i++) {
                        if (!overlays[i].point) return;

                        if (
                            overlays[i].point.lat === this.point.lat &&
                            overlays[i].point.lng === this.point.lng
                        ) {
                            this.map.removeOverlay(overlays[i]);
                            infoBox.close();
                        }
                    }
                });
                $closeBtn.on("click", () => {
                    infoBox.close();

                    this.addMarker(item);
                });
            });
            this.addActiveMarker(infoBox, item);
        },
        genMapIcon() {
            this.mapDataList.forEach((item) => {
                this.addMarker(item);
            });
        },
        iconFilter(type) {
            switch (type) {
                case "工业用地":
                    return this.gongIcon;
                case "商服用地":
                    return this.shangIcon;
                case "住宅用地":
                    return this.liveIcon;
                case "其他用地":
                    return this.qitaIcon;
            }
        },
        iconFilterActive(type) {
            switch (type) {
                case "工业用地":
                    return this.gongIconActive;
                case "商服用地":
                    return this.shangIconActive;
                case "住宅用地":
                    return this.liveIconActive;
                case "其他用地":
                    return this.qitaIconActive;
            }
        },
        addMarker(item) {
            const iconImg = this.iconFilter(item.tdyt);
            let iconWidth = 24;
            let iconHeight = 25;
            if (item.tdyt === "其他用地") {
                iconWidth = 32;
            }
            const poi = new BMap.Point(item.jd, item.wd);
            const icon = new BMap.Icon(iconImg, new BMap.Size(iconWidth, iconHeight));
            const marker = new BMap.Marker(poi, {
                icon,
            });

            this.map.addOverlay(marker);

            marker.addEventListener("click", () => {
                this.selectData = item;
                this.point = new BMap.Point(item.jd, item.wd);
                this.genInfoBox();
                this.map.removeOverlay(marker);
                // this.changeListPane(2);
            });
        },
        addActiveMarker(infoBox, item) {
            const iconImg = this.iconFilter(item.tdyt);
            let iconWidth = 24;
            let iconHeight = 25;
            if (item.tdyt === "其他用地") {
                iconWidth = 32;
            }
            const poi = new BMap.Point(item.jd, item.wd);
            const activeIcon = new BMap.Icon(
                iconImg,
                new BMap.Size(iconWidth, iconHeight)
            );
            const marker = new BMap.Marker(poi, {
                icon: activeIcon,
            });
            this.map.addOverlay(marker);

            infoBox.open(marker);

            marker.addEventListener("click", () => {
                this.selectData = null;
                infoBox.close();
                this.map.removeOverlay(marker);

                const newMarker = this.addMarker(item);
                this.map.addOverlay(newMarker);
            });
        },

        // 初始化地图收手绘工具
        initDrawingManager() {
            const styleOptions = {
                strokeColor: "red", //边线颜色。
                fillColor: "#fff", //填充颜色。当参数为空时，圆形将没有填充效果。
                strokeWeight: 3, //边线的宽度，以像素为单位。
                strokeOpacity: 0.8, //边线透明度，取值范围0 - 1。
                fillOpacity: 0.8, //填充的透明度，取值范围0 - 1。
                strokeStyle: "solid", //边线的样式，solid或dashed。
            };

            this.drawingManager = new BMapLib.DrawingManager(this.map, {
                isOpen: false, //是否开启绘制模式
                enableDrawingTool: false, //是否显示工具栏
                drawingToolOptions: {
                    offset: new BMap.Size(5, 5), //偏离值
                },
                circleOptions: styleOptions, //圆的样式
                polylineOptions: styleOptions, //线的样式
                polygonOptions: styleOptions, //多边形的样式
                rectangleOptions: styleOptions, //矩形的样式
            });
        },

        // 控框搜索
        dropSearch() {
            this.resetMeasureArea();
            this.mapDis.close();
            this.isShowRadiusSearch = false;

            if (this.mapDataList.length === 0) {
                // this.$Message.warning('请先搜索地块');
                this.$msg.error({
                    text: "请先搜索地块",
                });

                return;
            }

            this.isPullBoxOpen = !this.isPullBoxOpen;

            this.drawingManager.setDrawingMode(BMAP_DRAWING_POLYGON);
            this.drawingManager.disableCalculate();

            const callback = (e) => {
                const pts = e.overlay.Tn;
                const ply = new BMap.Polygon(pts);

                this.map.clearOverlays();
                this.pointList.forEach((item, index) => {
                    const result = BMapLib.GeoUtils.isPointInPolygon(item, ply);
                    if (result) {
                        this.addMarker(this.mapDataList[index]);
                    }
                });

                this.isPullBoxOpen = false;
                this.drawingManager.removeEventListener("overlaycomplete", callback);
            };

            this.drawingManager.addEventListener("overlaycomplete", callback);
            this.isPullBoxOpen ?
                this.drawingManager.open() :
                this.drawingManager.close();
        },

        // 半径搜索
        radiusSearch() {
            this.resetMeasureArea();

            if (!this.radius) {
                // this.$Message.warning('请输入完成信息');
                this.$msg.error({
                    type: "remind",
                    code: 4047,
                });

                return;
            }
            if (!/^-?\d+(\.\d+)?$/.test(this.radius)) {
                // this.$Message.warning('请输入正确的数字')
                // this.$msg.success("remind", 4046);
                this.$msg.error({
                    type: "remind",
                    code: 4046,
                });

                return;
            }
            if (this.radius > 5) {
                this.$msg.error({
                    type: "remind",
                    code: 4048,
                });

                this.radius = 5;
                return;
            }
            if (!this.point) {
                // this.$Message.warning('请选择中心点');
                this.$msg.error({
                    text: ["请先选择地块"],
                });

                return;
            }

            if (
                this.radiusSearchPoint &&
                this.point.lat === this.radiusSearchPoint.lat &&
                this.point.lat === this.radiusSearchPoint.lat
            ) {
                return;
            }

            this.map.clearOverlays();

            const circle = new BMap.Circle(this.point, this.radius * 1000, {
                strokeColor: "blue", //边线颜色。
                strokeStyle: "dashed", //边线的样式，solid或dashed。

                fillColor: "", //填充颜色。当参数为空时，圆形将没有填充效果。
                strokeWeight: 2, //边线的宽度，以像素为单位。
                strokeOpacity: 1, //边线透明度，取值范围0 - 1。
                fillOpacity: 0, //填充的透明度，取值范围0 - 1。
            });

            this.map.addOverlay(circle); //添加一个圆形覆盖物

            const newMapDataList = [];

            this.pointList.forEach((item, index) => {
                const result = BMapLib.GeoUtils.isPointInCircle(item, circle);

                if (result) {
                    this.addMarker(this.originMapDataList[index]);
                    newMapDataList.push(this.originMapDataList[index]);
                }
            });

            this.map.getOverlays().forEach((item, index) => {
                if (
                    index > 0 &&
                    item.point.lng === this.point.lng &&
                    item.point.lat === this.point.lat
                ) {
                    item.ba.click();
                }
            });

            this.mapDataList = newMapDataList.sort((a, b) => {
                return new Date(b.gpjzsj).getTime() - new Date(a.gpjzsj).getTime();
            });
        },

        // 测距
        initRanging() {
            this.mapDis = new BMapLib.DistanceTool(this.map);

            this.mapDis.addEventListener("ondrawend", () => {
                this.isRangingOpen = false;
            });
        },
        ranging() {
            this.resetMeasureArea();
            this.isShowRadiusSearch = false;
            this.isRangingOpen = !this.isRangingOpen;

            this.isRangingOpen ? this.mapDis.open() : this.mapDis.close();
        },

        // 测面积
        measureArea() {
            const self = this;
            this.mapDis.close();

            this.isShowRadiusSearch = false;

            this.isMeasureArea = !this.isMeasureArea;

            this.drawingManager.setDrawingMode(BMAP_DRAWING_POLYGON);
            this.drawingManager.enableCalculate();

            this.drawingManager.addEventListener("overlaycomplete", measureFn);
            this.isMeasureArea ?
                this.drawingManager.open() :
                this.drawingManager.close();

            function measureFn(e) {
                if (e.calculate === null) {
                    // self.$Message.warning('无法测量');
                    self.$msg.success({
                        text: "框选区域无效，请重新框选",
                    });

                    self.map.removeOverlay(e.overlay);
                    return;
                }
                e.label.ba.innerHTML = `
                    <span>区域面积：${e.calculate}m²</span>
                    <img id="polygonClose_${self.polygonIndex}" style="width:13px;height:13px;margin-left:4px;margin-right:4px;vertical-align:text-top;cursor:pointer" src="${self.overlayCloseIcon}" />
                `;

                self.polygonList.push(e.overlay);
                self.polygonLabelList.push(e.label);

                $(`#polygonClose_${self.polygonIndex}`).click((e) => {
                    const index = Number($(e.target).attr("id").match(/\d/)[0]);
                    self.map.removeOverlay(self.polygonList[index]);
                    self.map.removeOverlay(self.polygonLabelList[index]);
                    // self.polygonLabelList.splice(index, 1);
                });

                self.isMeasureArea = false;
                // self.clearable = true;
                self.polygonIndex++;

                self.drawingManager.removeEventListener("overlaycomplete", measureFn);
            }
        },
        resetMeasureArea() {
            this.drawingManager.close();
            this.isMeasureArea = false;
        },

        handleResultSort(item) {
            let index;
            this.sortType = item.id;
            item.checked = true;
            this.sortList.forEach((it, idx) => {
                if (item.id !== it.id) {
                    it.checked = false;
                } else {
                    index = idx;
                }
            });

            switch (item.id) {
                case 0:
                    if (item.type === "asc") {
                        this.orderBy = 2;
                    } else {
                        this.orderBy = 1;
                    }
                    break;
                case 1:
                    if (item.type === "asc") {
                        this.orderBy = 3;
                    } else {
                        this.orderBy = 4;
                    }
                    break;
                case 2:
                    if (item.type === "asc") {
                        this.orderBy = 5;
                    } else {
                        this.orderBy = 6;
                    }
                    break;
                default:
                    break;
            }
            item.type = item.type === "asc" ? "desc" : "asc";
            this.$set(this.sortList, index, item);
        },
        sortFn(type, a, b) {
            return type === "asc" ? b - a : a - b;
        },
        selectProvince(data) {
            const obj = this.landProvinceList.filter(
                (item) => item.areaId === data
            )[0];
            this.provinceId = data;
            this.province = obj.name;
            getLandSingleSelectCity({
                id: obj.areaId,
            }).then((data) => {
                this.cityList = data;
                this.city = "选择区域";
                this.cityId = "";
                this.region = "选择区域";
                this.regionId = "";
                this.regionList = [];
            });
        },
        selectCity(data) {
            const obj = this.cityList.filter((item) => item.areaId === data)[0];
            this.cityId = data;
            this.city = obj.name;
            getLandSingleSelectRegion({
                id: data,
            }).then((data) => {
                this.regionList = data;
                // this.region = this.regionList[0].name;
                // this.regionId = this.regionList[0].areaId;
                this.region = "";
                this.regionId = "";
            });
        },
        selectRegion(data) {
            const obj = this.regionList.filter((item) => item.areaId === data)[0];
            this.regionId = data;
            this.region = obj.name;
        },
        showRadiusSearch() {
            this.isShowRadiusSearch = true;
        },
        closeRadiusSearch() {
            this.isShowRadiusSearch = false;
        },
        scrollLoad(event) {
            const {
                target
            } = event;

            if (target.clientHeight + target.scrollTop === target.scrollHeight) {
                this.currentIndex++;
                this.search();
            }
        },
        changeListPane(index) {
            this.paneIndex = index;
        },
        initNearBySearch() {
            this.nearbySearch = new BMap.LocalSearch(this.map, {
                renderOptions: {
                    map: this.map,
                    autoViewport: false,
                    panel: "p-result",
                },
                onSearchComplete(result) {
                },
            });
        },
        searchNearBy(name) {
            if (this.point === null) {
                // this.$Message.warning('请先搜索并选择地块');
                this.$msg.success({
                    text: "请先搜索并选择地块",
                });

                return;
            }

            this.nearbySearch.clearResults();

            this.nearbySearch.searchNearby(
                name,
                this.point,
                this.radius * 1000 || 5000
            );
        },
        changeStartTime(data) {
            this.startTime[0] = data;
        },
        changeEndTime(data) {
            this.startTime[1] = data;
        },
        changeCloseTimeLeft(data) {
            this.closingTime[0] = data;
        },
        changeCloseTimeRight(data) {
            this.closingTime[1] = data;
        },
        clearOverlay() {
            this.map.clearOverlays();
            this.mapDataList = [];
            this.pointList = [];
            // this.clearable = false;
            this.point = null;
            this.radiusSearchPoint = null;
            this.total = 0;
        },
        showNearby(data) {
            const overlay = this.map.getOverlays();
            const point = this.point;

            overlay.forEach((item) => {
                if (!item.point || (item.ba && item.ba.className === "infoBox")) return;

                if (
                    point &&
                    item.point.lng == point.lng &&
                    item.point.lat == point.lat
                ) {
                    item.ba.click();
                }

                if (item.point.lng == data.jd && item.point.lat == data.wd && item.ba) {
                    this.map.setCenter(new BMap.Point(data.jd, data.wd));
                    item.ba.click();
                }
            });

            this.$nextTick(() => {
                this.changeListPane(2);
            });
        },
        handleAddProject(item) {
            this.addProjectId = item.id;
            this.address.lng = item.jd;
            this.address.lat = item.wd;
            this.showAddProjectModal = true;
        },
        clickResult(item) {
            let routeData = this.$router.resolve({
                path: "/Insight/PlaceDetail",
                query: {
                    id: item.id,
                    type: "option",
                },
            });
            window.open(routeData.href, "_blank");
        },
    },
};
</script>

<style lang="scss" scoped>
div {
    box-sizing: border-box;
}

.slide-down-enter,
.slide-down-leave-to {
    height: 0;
}

.slide-down-enter-active,
.slide-down-leave-active {
    transition: all 0.3s linear;
}

.map-wrapper {
    min-width: 1550px;
    padding: 0 20px 20px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    background-color: #fff;
    position: relative;
    overflow: hidden;

    .panel {
        width: 100%;
        height: 99px;
        padding-top: 3px;
        padding-left: 12px;
        padding-bottom: 20px;
        border: 1px solid #e3e3e5;
        color: #666;
        display: flex;
        position: absolute;
        top: 20px;
        overflow: hidden;
        background-color: #fff;
        z-index: 10;

        .pack-up {
            width: 150px;
            height: 26px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -26px;
            border-radius: 0 0 4px 4px;
            z-index: 5;
            background-color: #fff;
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16);
            text-align: center;
            cursor: pointer;
        }

        .col {
            width: 395px;
            margin-left: 70px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &:first-child {
                margin-left: 0;
            }

            &.more {
                width: auto;
                height: 34px;
                margin-top: 44px;
                flex-direction: row;
                align-items: center;
                position: absolute;
                right: 20px;

                .label-box {
                    cursor: pointer;

                    .label {
                        margin-right: 6px;
                    }
                }
            }

            .row {
                min-height: 32px;
                margin-top: 15px;
                display: flex;
                align-items: center;

                &:first-child {
                    margin-left: 0;
                }

                .label {
                    width: 71px;
                    text-align: right;
                }

                .options {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-left: 16px;

                    .option {
                        display: flex;
                        align-items: center;
                        margin-left: 13px;

                        &:first-child {
                            margin-left: 0;
                        }

                        .checkbox {
                            width: 14px;
                            height: 14px;
                            top: 0;
                            left: 0;
                            border: 1px solid #bababa;
                            border-radius: 3px;
                            background-color: #fff;
                            transition: border-color 0.2s ease-in-out,
                                background-color 0.2s ease-in-out;
                            cursor: pointer;
                            position: relative;

                            &.checked {
                                &::after {
                                    content: "";
                                    display: table;
                                    width: 4px;
                                    height: 8px;
                                    position: absolute;
                                    top: 1px;
                                    left: 4px;
                                    border: 2px solid #00b6ff;
                                    border-top: 0;
                                    border-left: 0;
                                    -webkit-transform: rotate(45deg) scale(1);
                                    transform: rotate(45deg) scale(1);
                                    -webkit-transition: all 0.2s ease-in-out;
                                    transition: all 0.2s ease-in-out;
                                }
                            }
                        }

                        .text {
                            width: auto;
                            height: 24px;
                            margin-left: 8px;
                            text-align: center;
                            line-height: 24px;
                            border-radius: 5px;
                        }

                        &.checkList {
                            cursor: pointer;
                            margin-right: 13px;
                            margin-left: 0;
                        }

                        &.deal-state-1 {
                            .text {
                                padding: 0 10px;
                                color: #fff;
                                background-color: #da7371;
                            }
                        }

                        &.deal-state-2 {
                            .text {
                                padding: 0 10px;
                                color: #fff;
                                background-color: #669a28;
                            }
                        }

                        &.deal-state-3 {
                            .text {
                                padding: 0 10px;
                                color: #fff;
                                background-color: #a6a6a6;
                            }
                        }

                        &.deal-state-4 {
                            .text {
                                padding: 0 10px;
                                color: #fff;
                                background-color: #ac779a;
                            }
                        }

                        &.start-time,
                        &.end-time {
                            margin: 0;

                            /deep/ .ivu-date-picker-rel {
                                .ivu-icon {
                                    display: none;
                                }
                            }

                            /deep/ .ivu-input {
                                padding-right: 7px;
                            }
                        }

                        &.empty {
                            /deep/ .ivu-btn {
                                background-color: #e1e5ec;

                                &:hover {
                                    background-color: #fff;
                                }
                            }
                        }
                    }

                    .hr {
                        width: 5px;
                        height: 2px;
                        background-color: #cfcfcf;
                        margin: 0 13px;
                    }

                    .unit {
                        margin-left: 5px;
                    }
                }

                &.landuse {
                    width: 900px;

                    .label {
                        flex-shrink: 0;
                    }
                }
            }
        }
    }

    .panel.active {
        height: auto;
        padding-left: 13px;
        padding-top: 4px;
        border: 0;
        box-shadow: 0 5px 6px -5px rgba(0, 0, 0, 0.16);
        overflow: visible;
    }

    .tool-bar {
        width: 100%;
        height: 40px;
        margin: 140px 0 0;
        padding-left: 12px;
        border: 1px solid #e3e3e5;
        color: #333;
        display: flex;
        align-items: center;

        .position {
            display: flex;

            .options {
                display: flex;
                align-items: center;

                .label {
                    margin-right: 10px;
                }

                .option {
                    min-width: 60px;
                    margin-right: 30px;
                    color: #00b6ff;

                    &.district {
                        a {
                            color: #c5c8ce;
                            cursor: not-allowed;
                        }
                    }
                }
            }

            & /deep/ .ivu-dropdown {
                position: relative;

                .ivu-select-dropdown {
                    max-height: 200px;
                    overflow: scroll;
                }

                a {
                    color: #00b6ff;
                }
            }
        }

        .tools {
            margin-left: 40px;
            display: flex;

            .options {
                display: flex;
                align-items: center;

                .label {
                    margin-right: 10px;
                }

                .option {
                    margin-right: 30px;
                    cursor: pointer;

                    &.radius {
                        position: relative;

                        .btn {
                            .text {
                                margin-right: 8px;
                            }

                            &.active {
                                & /deep/ .ivu-icon {
                                    transform: rotate(180deg);
                                }
                            }
                        }

                        .border {
                            position: absolute;
                            top: -5px;
                            left: -10px;
                            width: 94px;
                            height: 32px;
                            border: 1px solid rgb(177, 177, 177);
                            border-radius: 4px 4px 0 0;

                            &::after {
                                content: "";
                                width: 100%;
                                height: 1px;
                                position: absolute;
                                bottom: -1px;
                                z-index: 12;
                                background-color: #fff;
                            }
                        }

                        .search-box {
                            width: 150px;
                            height: 46px;
                            padding-left: 8px;
                            padding-right: 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid rgb(177, 177, 177);
                            position: absolute;
                            top: 26px;
                            left: -10px;
                            z-index: 11;
                            background-color: #fff;
                            font-size: 12px;

                            .input-box {
                                margin-right: 10px;

                                &.km {
                                    span {
                                        margin-left: 5px;
                                        vertical-align: middle;
                                    }
                                }

                                & /deep/ .ivu-input {
                                    font-size: 12px;
                                }
                            }
                        }
                    }

                    &.disabled {
                        color: #ccc;
                        cursor: no-drop;
                    }
                }
            }
        }
    }

    .map-contaner {
        width: 100%;
        height: 802px;
        position: relative;

        .map {
            width: 100%;
            height: 100%;
        }

        .result-list {
            width: 329px;
            height: 100%;
            background-color: #fff;
            position: absolute;
            top: 0;
            right: 0;

            .tabs {
                width: 100%;
                height: 50px;
                border-bottom: 1px solid #4373e8;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .tab {
                    width: 117px;
                    height: 49px;
                    line-height: 50px;
                    margin: 0;
                    border: none;
                    border-radius: 0;
                    background-color: #fff;
                    text-align: center;
                    cursor: pointer;

                    &:hover {
                        color: #57a3f3;
                    }

                    &.active {
                        color: #fff;
                        background-color: #00b6ff;

                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }

            .pane {
                width: 100%;
                height: 100%;

                .list {
                    width: 100%;
                    height: 756px;
                    overflow: auto;

                    &.list1 {

                        padding-top: 30px;
                    }

                    .banner {
                        position: absolute;
                        width: 100%;
                        height: 30px;
                        padding-left: 10px;
                        padding-right: 7px;
                        background-color: #f4f4f4;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        top: 50px;

                        .sort {
                            display: flex;
                            align-items: center;

                            .item {
                                margin-right: 10px;
                                cursor: pointer;

                                &:nth-child(3) {
                                    margin-right: 0px;
                                }

                                .sort-icon {
                                    display: inline-block;
                                    width: 10px;
                                    height: 12px;
                                    background-image: url(~@/assets/image/map/sort.png);

                                    &.active {
                                        background-image: url(~@/assets/image/map/sort_active.png);
                                    }
                                }
                            }
                        }
                    }

                    .result {
                        width: 100%;
                        height: 93px;
                        padding: 22px 0px 9px 7px;
                        cursor: pointer;

                        .search-result {
                            border-bottom: 1px solid #cccccc;
                        }

                        .row {
                            width: 100%;
                            height: 25px;
                            margin-bottom: 13px;
                            display: flex;
                            align-items: center;

                            .tab {
                                width: 60px;
                                height: 25px;
                                margin-right: 8px;
                                color: #fff;
                                line-height: 25px;
                                text-align: center;
                                border-radius: 2px;

                                &.deal-state {
                                    background-color: #da7371;
                                }

                                &.land-use {
                                    padding: 5px;
                                    font-size: 12px;
                                    line-height: 12px;
                                    border: 1px solid #ffa160;
                                    color: #ffa160;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }

                            .title {
                                display: inline-block;
                                max-width: 150px;
                                color: #333;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            .price {
                                flex-grow: 1;
                                text-align: right;

                                .num {
                                    color: #ff3333;
                                    font-size: 16px;
                                }

                                &.lmj {
                                    max-width: 94px;
                                }
                            }

                            .link {
                                margin-right: 12px;

                                &.zb {
                                    margin-right: 8px;
                                }
                            }

                            .date {
                                min-width: 50px;
                            }
                        }
                    }

                    .nearby-banner {
                        width: 100%;
                        height: 30px;
                        padding-left: 10px;
                        padding-right: 7px;
                        text-align: center;
                        padding: 15px 10px 0;
                        font-size: 12px;
                        color: #666;

                        .first {
                            height: 24px;
                            padding-bottom: 10px;
                            border-bottom: 1px solid #eee;
                            display: flex;
                            justify-content: space-around;
                            align-items: center;

                            .item {
                                height: 24px;
                                background: url("~@/assets/image/map/mapIcons.png") no-repeat;
                                padding-left: 32px;
                                line-height: 24px;
                                cursor: pointer;
                            }
                        }

                        .second {
                            text-align: center;
                            margin: 17px auto;

                            .item {
                                cursor: pointer;
                                text-align: center;
                                border-right: 1px dashed #eee;
                                height: 14px;
                                line-height: 14px;
                                width: 74px;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }

    /deep/ .info-box-inner {
        position: relative;
        background-color: #fff;

        &::after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            left: 50%;
            bottom: -27px;
            transform: translateX(-50%);
            border-top: 9px solid #fff;
            border-right: 9px solid transparent;
            border-bottom: 9px solid transparent;
            border-left: 9px solid transparent;
        }

        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
            font-size: 14px;
            font-weight: bold;
            color: #333;

            .text {
                max-width: 210px;
                display: flex;

                span {
                    display: inline-block;
                    max-width: 155px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .close {
                width: 10px;
                height: 10px;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                    vertical-align: text-top;
                }
            }
        }

        .info {
            margin-bottom: 12px;

            .row {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .col {
                    max-width: 128px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &.price {
                        span {
                            color: #ac1812;
                            font-size: 14px;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .tool {
            margin-bottom: 8px;

            .item {
                margin-right: 16px;
            }
        }

        .search {
            .tabs {
                height: 26px;
                margin-bottom: 6px;
                display: flex;
                align-items: center;
                justify-content: center;

                .tab {
                    flex: 1;
                    height: 100%;
                    line-height: 26px;
                    background-color: #f5f5f5;
                    text-align: center;
                    border: 1px solid #eaeaea;
                    cursor: pointer;

                    &.active {
                        color: #ac1812;
                        background-color: #fff;
                        border-left: none;
                        border-right: none;
                        border-bottom: none;
                    }
                }
            }

            .query {
                padding-left: 5px;
                padding-right: 3px;
                position: relative;

                .dis {
                    width: 60px;
                    height: 24px;
                    padding-left: 10px;
                    font-size: 12px;
                    border: 1px solid #e4e4e4;
                    border-radius: 5px;
                    appearance: none;
                    cursor: pointer;
                    outline: none;
                }

                .icon-down {
                    width: 9px;
                    height: 5px;
                    color: #b7b7b7;
                    display: inline-block;
                    font-family: Ionicons;
                    font-style: normal;
                    text-align: center;
                    position: absolute;
                    left: 51px;
                    top: 4px;
                    cursor: pointer;

                    &::before {
                        content: "\f116";
                    }
                }

                .keyword {
                    width: 99px;
                    height: 24px;
                    padding-left: 8px;
                    padding-right: 8px;
                    margin-left: 12px;
                    margin-right: 11px;
                    border-radius: 5px;
                    border: 1px solid #e4e4e4;
                }

                .search-btn {
                    width: 45px;
                    height: 24px;
                    background-color: #00b6ff;
                    border-radius: 5px;
                    outline: none;
                    border: none;
                    color: #fff;
                    cursor: pointer;

                    &:hover {
                        background-color: rgb(37, 193, 255);
                    }
                }
            }
        }
    }
}
</style>

import { post, get } from "@/utils/http";

// 搜索
export function getMapData(params) {
    return post({ url: "/service-insight/query_land/mapSearch", params });
}

// 获取交易维度数据
export function getDealState() {
    return get({ url: "/service-insight/land_dim/dealState" });
}

// 获取土地用途维度数据
export function getLandUse() {
    return get({ url: "/service-insight/land_dim/landUse" });
}

// 获取土地用途维度数据
export function getSellway() {
    return get({ url: "/service-insight/land_dim/sellWay" });
}
